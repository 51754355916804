var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Worktime } from '../../../enum';
import { usePlatformContext } from '../../app/selectors';
import { Autocomplete, FallbackComponent, FormField } from '../../common';
import { useWizardParams } from '../selectors';
import { useCreateQueueAPI, useQueuesAPI, useWorkTypeAPI } from './selectors';
import { WorkType } from './types';
var defaultValues = {
    businessArea: '',
    worktypeId: WorkType.InboundCalls,
    worktime: Worktime.NonDeferrable,
};
export var WORK_TIMES = [
    { name: 'Non-deferrable', id: Worktime.NonDeferrable },
    { name: 'Deferrable', id: Worktime.Deferrable },
];
var validateForm = yup.object().shape({
    businessArea: yup.string().required().max(24).trim().quotesNotAllowed().label('Name'),
    worktypeId: yup.number().integer().required(),
    worktime: yup.number().integer().required(),
});
export var CreateQueueForm = function () {
    var t = useTranslation('wizard', { keyPrefix: 'queue.form' }).t;
    var _a = useWorkTypeAPI(), workTypeData = _a[0], fetchWorkType = _a[1];
    var _b = useWizardParams(), basePlanId = _b.basePlanId, planId = _b.id;
    var _c = useCreateQueueAPI(), createQueueData = _c[0], createQueue = _c[1], clearCreateQueueData = _c[2];
    var _d = useQueuesAPI(), queuesData = _d[0], fetchQueues = _d[1];
    var context = usePlatformContext();
    var _e = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(validateForm),
    }), control = _e.control, reset = _e.reset, handleSubmit = _e.handleSubmit, watch = _e.watch, getValues = _e.getValues, setValue = _e.setValue;
    var worktypeId = watch('worktypeId');
    useEffect(fetchWorkType, [fetchWorkType]);
    useEffect(function () {
        if (createQueueData.data) {
            reset(defaultValues);
            fetchQueues();
        }
        return clearCreateQueueData;
    }, [reset, createQueueData.data, clearCreateQueueData, queuesData.requesting, fetchQueues]);
    var getWorktimeDisabled = useCallback(function (worktime) {
        return worktime === Worktime.Deferrable && [WorkType.InboundCalls, WorkType.Chat].includes(worktypeId);
    }, [worktypeId]);
    useEffect(function () {
        var worktime = getValues().worktime;
        if (getWorktimeDisabled(worktime)) {
            setValue('worktime', Worktime.NonDeferrable);
        }
    }, [getValues, getWorktimeDisabled, setValue]);
    var handleCreateQueue = handleSubmit(function (values) {
        createQueue(__assign(__assign({}, values), { basePlanId: basePlanId, planId: planId }));
    });
    return (_jsx(FallbackComponent, __assign({ overlay: true, requesting: !!createQueueData.requesting || !!workTypeData.requesting }, { children: _jsxs(Stack, __assign({ spacing: 4, height: "inherit", component: "form", onSubmit: handleCreateQueue }, { children: [_jsx(Typography, __assign({ variant: "h3" }, { children: t('title', { context: context }) })), _jsx(FormField, __assign({ required: true, name: "businessArea", label: t('businessArea.label', { context: context }), control: control }, { children: _jsx(TextField, { inputProps: {
                            maxLength: 24,
                            placeholder: t('businessArea.placeholder', { context: context }),
                        } }) })), _jsx(FormField, __assign({ required: true, name: "worktypeId", label: t('workType', { context: context }), control: control }, { children: _jsx(Autocomplete, { options: workTypeData.data, loading: workTypeData.requesting }) })), _jsx(FormField, __assign({ required: true, name: "worktime", label: t('workTime'), control: control }, { children: _jsx(Autocomplete, { options: WORK_TIMES, getOptionDisabled: function (_a) {
                            var id = _a.id;
                            return getWorktimeDisabled(id);
                        } }) })), _jsx(Button, __assign({ style: { marginTop: 'auto' }, variant: "outlined", type: "submit" }, { children: t('saveQueue', { context: context }) }))] })) })));
};
